.box-group {
    margin: 100px 0;

    /* Container Element */
    &__head {
        margin-bottom: 50px;
    }

    &__body {
    }

    &__inner {
        position: relative;
    }


    /* Custom Element */
    &__title {
        font-size: 26px;
        font-weight: 500;
    }

    &__description {
        font-size: 13px;
        font-weight: 500;
        line-height: 1.6;
    }

    &__btn {
        min-width: 185px;
    }

    &__image {
    }

    @include media-breakpoint-down(md) {
        margin: 50px 15px;

        &__image {
            max-width: 100%;
        }
    }


    /* Box Group Type */
    &--promo {
        position: relative;
        height: calc(100vh - #{4vh * 2});
        min-height: 540px;
        margin-left: 4vh;
        margin-right: 4vh;
        margin-top: -131px;
        background-color: $twilight-blue;
        background-image: url(#{$img}slider-background.png);
        background-size: cover;

        @include media-breakpoint-down(md) {
            margin-left: 0;
            margin-right: 0;
            height: 100vh;
            margin-top: -60px;
        }

        @include media-breakpoint-only(md) {
            height: 80vh;
        }
    }

    &--properties {

    }

    &--catalog {
        .box-group__inner {
            padding: 60px 80px;
            overflow: hidden;
        }

        .box-group__image {
            position: absolute;
            right: 0;
            bottom: 0;
        }

        @include media-breakpoint-down(md) {
            .box-group__inner {
                padding: 30px;
                text-align: center;
            }

            .box-group__image {
                position: relative;
                margin-top: 30px;
                margin-bottom: -30px;
            }
        }
    }

}

.home-promo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &__row {
        width: 100%;
    }

    &__title {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: -1px;
        transform: translate(0, -30px);
        opacity: 0;
        transition: all ease 0.3s;

        b {
            font-size: 44px;
            display: block;
            font-weight: 500;
            margin-bottom: -20px;
        }

        .home-promo--active & {
            opacity: 1;
            transform: translate(0, 0);
        }
    }

    &__btn {
        min-width: 185px;
        transform: translate(0, 30px);
        opacity: 0;
        transition: all ease 0.3s;

        .home-promo--active & {
            opacity: 1;
            transform: translate(0, 0);
        }
    }

    &__image {
        position: absolute;
        z-index: 3;
        right: 15px;
        bottom: -4vh;
        width: 365px;
        height: calc(100vh - 8vh - 131px);
        object-fit: cover;
        transform: translate(0, 100%);
        opacity: 0;
        transition: all ease 0.3s;

        .home-promo--active & {
            opacity: 1;
            transform: translate(0, 0);
        }
    }

    &--active {
        z-index: 1;
    }

    @include media-breakpoint-only(md) {
        &__image {
            width: 50%;
            padding-left: 30px;
            bottom: 20px;
        }
    }

    @include media-breakpoint-down(sm) {
        text-align: center;

        &__row {
            width: auto;
        }

        &__image {
            display: none;
        }
    }
}

.home-promo-nav {
    position: absolute;
    z-index: 2;
    bottom: 80px;
    left: 0;
    width: 100%;

    &__group {
        height: 10px;
        display: flex;
        border-bottom: 1px solid #fff;
    }

    &__item {
        height: 100%;
        border-bottom: 3px solid transparent;
        width: 100%;
        cursor: pointer;

        &--active {
            border-color: #fff;
        }
    }

    &__current {
        color: fade_out(#fff, 0.5);
        text-align: right;
        font-size: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        line-height: 0.7;
        margin-bottom: 20px;

        small {
            font-size: 39px;
            font-weight: 600;
            margin-right: -2px;
        }
    }

    @include media-breakpoint-down(md) {
        bottom: 20px;
    }
}

.home-product {
    &__item {

        &:hover {
            .home-product__image {
                background: $twilight-blue;
            }
        }
    }

    &__image {
        height: 385px;
        background: $pale-grey-two;
        transition: all ease 0.3s;
    }

    &__text {
    }

    &__title {
        font-size: 22px;
        font-weight: 600;
    }

    &__description {
        font-size: 13px;
        font-weight: 500;
        line-height: 1.6;
        margin-top: 10px;
    }

    @include media-breakpoint-down(md) {
        &__item {
            margin-bottom: 20px;
        }
    }


    &--carousel {
        @include media-breakpoint-down(md) {
            display: block;
        }
    }
}

.home-usage-area {
    &__item {
        position: relative;
        padding-left: 12px + 30;
        margin: 60px 0;

        &:before {
            content: '';
            position: absolute;
            top: 8px;
            left: 0;
            width: 12px;
            height: 12px;
            border-radius: 12px;
            background: $twilight-blue;
        }
    }

    &__title {
        font-size: 20px;
        font-weight: 500;
    }

    &__description {
        font-size: 13px;
        font-weight: 500;
        line-height: 1.6;
        margin-top: 10px;
    }

    @include media-breakpoint-down(md) {
        &__item {
            margin: 0;

            & + & {
                margin-top: 20px;
            }
        }
    }
}

.home-faq {
    margin-top: -35px;

    &__item {
        position: relative;
    }

    &__image {
    }

    &__text {
        padding: 60px;
        background: $pale-grey-two;
        height: 100%;
        max-height: 455px;
        min-height: 455px;
        margin-top: 110px;
    }

    &__title {
        font-size: 20px;
        font-weight: 500;
    }

    &__description {
        font-size: 13px;
        font-weight: 500;
        line-height: 1.6;
        margin-top: 30px;
    }

    &__nav {
        font-size: 60px;
        font-weight: 400;
        letter-spacing: -1px;
        line-height: 0.9;
        margin-right: 30px;
        color: $twilight-blue;

        small {
            font-size: 39px;
            font-weight: 600;
        }
    }

    .flickity-page-dots {
        bottom: 0;
        width: 50%;
        left: 50%;
        text-align: left;
        padding-left: 40px;
        padding-bottom: 40px;
    }

    .flickity-page-dots .dot {
        width: 10px;
        height: 10px;
        opacity: 1;
        background: $pale-grey-four;
        margin: 0 3px;
    }

    .flickity-page-dots .dot.is-selected {
        background: $twilight-blue;
    }

    @include media-breakpoint-down(md) {
        &__text {
            padding: 30px;
            max-height: inherit;
        }
    }

    @include media-breakpoint-down(sm) {
        margin-top: 0;

        &__text {
            margin-top: 0;

            padding: 30px;
            max-height: inherit;
        }

        &__image {
            max-width: 100%;
        }

        .flickity-page-dots {
            left: 0;
            width: 100%;
            padding: 0;
            bottom: -25px;
            text-align: center;
        }
    }
}
