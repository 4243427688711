body {
	&.homepage-body {
		.site-head {
			background: none;
		}

        @include media-breakpoint-down(md) {
            padding-top: 60px;

            .site-head {
                background: $twilight-blue;
            }
        }
	}

    @include media-breakpoint-down(md) {
        padding-top: 60px + 15;
    }
}

@import "header";
@import "footer";
@import "section";
@import "page";
@import "box-group";
@import "guide";
@import "category";
@import "product";
@import "contact";
@import "cart";
