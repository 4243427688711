.site-head {
    background: $twilight-blue;
    position: relative;
    z-index: 10;
    padding: 30px 0;
    margin: 4vh;
    margin-bottom: 0;

    .block {
        &--left {
        }

        &--right {
        }
    }

    @include media-breakpoint-down(md) {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 15px 0;

        .block {
            &--left {
            }

            &--right {
                display: none;
                padding: 30px 0;
            }
        }
    }
}

.head-logo {
    &__link {
    }

    &__image {
    }
}

.head-menu {
    &__link {
        display: block;
        font-size: 16px;
        font-weight: 500;
        color: fade_out(#fff, 0.5);
        margin: 0 (25px / 2);
        border-bottom: 3px solid transparent;
        padding: 25px 10px;
        transition: all ease 0.3s;

        &--active, .show & {
            color: #fff;
            border-bottom-color: #fff;
        }

        &:hover {
            color: #fff;
        }
    }

    &__dropdown {
        min-width: 620px;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.11);
        border-radius: 0;
        padding: 40px;
        margin-left: -2px;
        margin-top: -1px;
    }

    &__dropdown-link-box {
        margin: -25px 0;
    }

    &__dropdown-link {
        display: flex;
        align-items: center;

        font-size: 16px;
        line-height: 1.4;
        font-weight: 500;
        color: fade_out($dark-slate-blue-two, 0);
        transition: all ease 0.3s;
        padding: 25px 0;
        background: none $i;

        &:hover {
            color: $azul;

            .head-menu__dropdown-link-arrow {
                opacity: 1;
            }
        }
    }

    &__dropdown-link-arrow {
        margin-left: 20px;
        font-size: 14px;
        opacity: 0;
        transition: all ease 0.3s;
    }

    &__dropdown-image-box {
        position: relative;
        width: 100%;
        margin: -30px 0;
    }

    &__dropdown-image {
        position: absolute;
        top: 50%;
        right: -20px;
        transform: translate(0, -50%);
        height: 100%;
        display: none;
    }

    @include media-breakpoint-down(md) {
        border-bottom: 1px solid fade_out(#fff, 0.9);
        padding-bottom: 30px;
        margin-bottom: 30px;
        margin-top: 30px;

        &__dropdown {
            position: relative;
            background: none;
            display: block;
            box-shadow: none;
            min-width: inherit;
            float: none;
            border: none;
            padding: 0;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid fade-out(#fff, 0.8);
            margin-left: 0;
        }

        &__dropdown-link {
            color: fade_out(#fff, 0.5);
            font-size: 14px;
            font-weight: 400;
            padding: 10px 0;
            
            &:hover {
                color: #fff;
            }
        }

        &__dropdown-link-arrow {
            display: none;
        }

        &__dropdown-image-box {
            display: none;
        }

        &__dropdown-link-box {
            margin: 0;
        }

        &__link {
            margin: 0;
            padding: 15px 0;
            border: none;
            font-size: 18px;
            text-align: left;
        }
    }
}

.head-contact {
    &__label {
        font-size: 13px;
        font-weight: 500;
        color: #fff;
    }

    &__value {
        font-size: 20px;
        font-weight: 600;
        color: #fff;

        &:hover {
            color: #fff;
            text-decoration: underline;
        }
    }
}

.head-cart {
    position: relative;

    &__btn {
        color: #fff;
        padding: 0;
        border: none;

        &:hover {
            color: #fff;
        }
    }

    &__icon {
        font-size: 24px;
    }

    &__badge {
        padding: 0;
        position: absolute;
        top: -10px;
        right: -10px;
        width: 18px;
        height: 18px;
        border-radius: 18px;
        font-size: 11px;
        line-height: 18px;
        font-weight: 400;
    }
}
