// Settings
// ==================================================
$hamburger-padding-x           : 0 !default;
$hamburger-padding-y           : 0 !default;
$hamburger-layer-width         : 28px !default;
$hamburger-layer-height        : 2px !default;
$hamburger-layer-spacing       : 5px !default;
$hamburger-layer-color         : #fff !default;
$hamburger-layer-border-radius : 10px !default;
$hamburger-hover-opacity       : 1 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;