@import "../plugins/font-awesome";

/* ===== Helper */
$img: '../images/';
$i: !important;


/* ===== Fonts */
$ff: 'Poppins';

/* ===== Colors */
$dark-slate-blue-two: #183d65 !default;
$azul: #1654d7 !default;
$twilight-blue: #0b3691 !default;
$pale-grey-four: #dde5e9 !default;
$pale-grey-two: #eff6fa !default;
$dark-slate-blue: #162a4c !default;
$black-two: #252525 !default;
