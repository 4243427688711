
.page-breadcrumb {
    padding: 30px 0;
    padding-top: 0;
    margin: 0 4vh;
    position: relative;
    z-index: 5;

    &__link {
        font-size: 14px;
        font-weight: 400;
        color: fade_out(#fff, 0.5);

        &:after {
            content: '/';
            display: inline-block;
            margin: 0 5px;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }

        &:hover {
            color: #fff;
        }
    }

    @include media-breakpoint-down(md) {
        margin: 0;
        margin-top: -15px - 10;
        padding: 15px 0;
    }
}

.page-head {
    margin-bottom: 50px;

    &__title {
        font-size: 32px;
        font-weight: 500;
    }

    &__sub-title {
        font-size: 20px;
        font-weight: 500;
        margin-top: 20px;
    }

    &__description {
        font-size: 13px;
        font-weight: 400;
        margin-top: 20px;
    }

    &__image {
        max-width: 100%;
    }

    &__btn {
        min-width: 180px;
    }

    &--dark {
        margin-left: 4vh;
        margin-right: 4vh;
        color: #fff;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 30px;

        &__title {
            font-size: 26px;
        }

        &__description {
        }

        &--dark {
            margin-left: -15px;
            margin-right: -15px;
        }
    }

    @include media-breakpoint-down(sm) {
        &__title , &__description {
            text-align: center;
        }
    }
}

.page-content {
    margin: 50px 0;

    @include media-breakpoint-down(md) {
        margin: 30px 15px;
    }
}

.page-search {
    position: relative;

    &__input {
        height: 60px;
        font-size: 13px;
    }

    &__btn {
        position: absolute;
        top: 0;
        right: 0;
        height: 60px;
    }

    &__btn-icon {
        font-size: 14px;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 20px;
    }
}

.page-share {
    &__label {
        font-size: 14px;
        font-weight: 500;
        margin-right: 10px;
    }

    &__list {
    }

    &__link {
        color: $dark-slate-blue-two;
        width: 28px;
        height: 28px;
        border-radius: 3px;
        transition: all ease 0.3s;
        margin: 0 2px;

        &:hover {
            background: $azul;
            color: #fff;
        }
    }

    &__icon {
        font-size: 16px;
        line-height: 16px;
    }
}

.page-author {
    &__image {
    }

    &__name {
        font-size: 14px;
        font-weight: 500;
    }
}

.page-article {
    @include article();
}

.page-form {
    &__title {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 30px;
    }

    &__input {
    }

    &__btn {
    }
}

.page-btn-group {

    background: $pale-grey-two;
    border: 1px solid $pale-grey-four;
    border-radius: 5px;
    overflow: hidden;

    &__btn {
        font-size: 14px;
        color: $black-two;

        &--active {
            background: #fff;
            color: $twilight-blue;
            border: 1px solid $pale-grey-four;
            margin: -1px;
        }
    }

    @include media-breakpoint-down(md) {
        margin-top: 20px;

        &__btn {
            font-size: 12px;
            line-height: 1.2;
        }
    }
}

article {
    @include article();
}
