.product-gallery {
    margin-top: -80px;

    &__item {
        display: block;
        width: 100%;
        text-align: center;
    }

    @include media-breakpoint-down(lg) {
        &__image {
            max-width: 100%;
        }
    }
}

.product-gallery-thumb {
    width: (40px + 10) * 3;

    &__item {
        width: 40px;
        height: 40px;
        margin-left: 10px;
        background: $pale-grey-four;

        &.is-selected {
            background: $twilight-blue;
        }
    }

    &__image {
        height: 100%;
    }
}

.product-block {
    margin: 100px 0;

    &__title {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 40px;
    }

    &__description {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.6;
    }

    @include media-breakpoint-down(lg) {
        &__image {
            max-width: 100%;
        }
    }
}

.product-properties {
    &__item {
        font-size: 12px;
        font-weight: 600;
        height: 70px;
        background: $pale-grey-two;
        padding: 30px;

        &:nth-child(2n+2) {
            background: #fff;
        }
    }

    &__label {
        opacity: 0.3;
    }

    &__value {
    }
}

.product-zigzag {
    &__title {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 40px;
    }

    &__description {
        font-size: 13px;
        line-height: 1.6;
        font-weight: 500;
    }

    &__image {
        width: 100%;
    }

    @include media-breakpoint-down(md) {
        &__image {
            max-width: 100%;
        }
    }
}
