.contact-block {
    margin-bottom: 60px;

    &__title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 30px;
    }

    &__body {
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 20px;

        &__title {
            margin-bottom: 15px;
        }
    }
}

.address-info {
    &__item {
        margin-bottom: (16px * (0.25 * 8));
    }

    &__select {
    }

    &__select-btn {
        background: #fff $i;
    }

    &__text {
        font-size: 16px;
        line-height: 1.6;
        font-weight: 400;
    }

    &__link {
        font-size: 18px;
        font-weight: 500;
        color: $dark-slate-blue-two;

        &:hover {
            text-decoration: underline;
        }
    }

    &__icon {
        font-size: 22px;
        line-height: 22px;
        color: $twilight-blue;
        margin-top: 2px;
    }

    &__maps {
        width: 100%;
    }

    @include media-breakpoint-down(sm) {
        .w-50 {
            width: 100% $i;
        }
    }
}

.sales-team {
    &__item {
        & + & {
            border-top: 1px solid $pale-grey-four;
            padding-top: 30px;
            margin-top: 30px;
        }
    }

    &__image {
    }

    &__name-surname {
        font-size: 18px;
        font-weight: 500;
    }

    &__email {
        font-size: 13px;
        font-weight: 500;
        margin-top: 5px;
    }

    &__btn {
    }
}
